import { register } from 'register-service-worker'

const currentDomain = window.location.hostname;

if (process.env.NODE_ENV === 'production') {
  if (!(currentDomain == 'nonpwa.platform.testing.easytranslate.com' || currentDomain == 'nonpwa.platform.easytranslate.com')) { 
    self.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'SKIP_WAITING') {
        self.skipWaiting()
      }
    })
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
        )
      },
      registered(registration) {
        console.log(
          'Service worker has been registered and now polling for updates.'
        )
        setInterval(() => {
          registration.update()
        }, 1000 * 60 * 2)
      },
      cached() {
        console.log('Content has been cached for offline use.')
      },
      updatefound() {
        console.log('New content is downloading.')
      },
      updated(registration) {
        console.log('New content is available; please refresh.')
        document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
        )
      },
      offline() {
        console.log(
          'No internet connection found. App is running in offline mode.'
        )
      },
      error(error) {
        console.error('Error during service worker registration:', error)
      }
    })
  } else {
    // Unregister service worker for other domains
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister().then(function (boolean) {
            if (boolean) {
              console.log('Unregistration successful');
            } else {
              console.log('Unregistration failed');
            }
          });
        }
      });
    }
  }
}

